import React from 'react'
// import bg from '../assets/bg.png'
import Navabar from './Navabar'
import dog from '../assets/dog.jpg'
import walletBg from '../assets/wallet-bg.png'
import telegramMin from '../assets/telegamMin.svg'
import twiterMin from '../assets/twiterMin.svg'
import main from '../assets/hero.png'
import InfiniteSlider from './InfiniteSlider'
import { Link } from 'react-router-dom'


const Header = () => {

    return (
        <>
            <div className="bg-fixed h-screen" style={{ background: `linear-gradient(to right, rgba(0, 0, 0, .50), rgba(0, 0, 0, .50)), url(${dog})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center center' }}>
                <InfiniteSlider text='BITCOIN DOG'/>
                <Navabar />

                <div className="flex justify-between items-center w-full px-5">

                    <div className="flex flex-col gap-5  w-full justify-center items-center">
                        <img src={main} className='md:w-1/4' alt="" />
                        <h1 className='font-bold font-Pixelify text-2xl text-center md:text-start md:text-4xl text-white' >NEXT-GEN GAME ON BITCOIN BLOCKCHAIN</h1>

                        {/* <div className="flex flex-col gap-8 md:gap-4 justify-center md:items-start items-center">
                            <h1 className='font-bold font-Pixelify text-5xl text-center md:text-start md:transform-none md:scale-y-100 transform scale-y-150 md:text-[8rem] text-[#ffe718]' >BITCOIN DOG</h1>
                            <h1 className='text-white font-chakra md:text-start text-center md:w-auto  text-xl md:text-4xl'>The Gaming Platform for Bitcoin Ecosystem</h1>
                        </div> */}
                        <div className='flex flex-col md:flex-row gap-4 md:gap-10'>
                            <div className='relative flex justify-center'>
                                <img src={walletBg} className='md:w-auto w-56' alt="" />
                                <Link to='https://t.me/BitcoinDogOfficial' className=' absolute top-1/2 left-1/2 md:left-[45%]  transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-2 md:gap-4'>
                                    <img src={telegramMin} className='md:w-auto w-10' alt="" />
                                    <h1 className='font-normal text-2xl md:text-3xl font-Pixelify text-[#ce583b]'>TELEGRAM</h1>
                                </Link>
                            </div>
                            <div className='relative flex justify-center'>
                                <img src={walletBg} className='md:w-auto w-56' alt="" />
                                <Link to='https://x.com/bitcoindogworld' className='absolute top-1/2 left-1/2 md:left-[45%]  transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-2 md:gap-4'>
                                    <img src={twiterMin} className='md:w-auto w-10' alt="" />
                                    <h1 className='font-normal font-Pixelify text-2xl md:text-3xl text-[#ce583b]'>TWITTER</h1>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
