import './App.css';
import Home from './pages/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Farming from './pages/Farming';
import GamePlay from './pages/GamePlay';
import LaunchPad from './pages/LaunchPad';
import MarketPlace from './pages/MarketPlace';
import MarketPlace2 from './pages/MarketPlace2';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/farming' element={<Farming/>} />
      <Route path='/gamePlay' element={<GamePlay/>} />
      <Route path='/launchPad' element={<LaunchPad/>} />
      <Route path='/marketPlace' element={<MarketPlace/>} />
      <Route path='/marketPlace2' element={<MarketPlace2/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
