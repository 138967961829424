import React from 'react'
import logo from '../assets/Dog Transparent 1.png'
// import dog from '../assets/dog.jpg'
import coin from '../assets/coin.PNG'
import game from '../assets/play.JPEG'
// import log1 from '../assets/f1 (1).PNG'
import log2 from '../assets/f1 (2).PNG'
import InfiniteSlider from './InfiniteSlider'



const Feautres = () => {
    return (
        <div className=' relative'>
            <div className='text-xl   md:px-24 px-5 bg-[#5ED86E] ' >
                <div className='flex flex-col justify-start gap-6 items-center w-full border-4 py-3 border-white rounded-3xl'>
                    <h1 className='uppercase font-Pixelify font-bold  text-center leading-[50px] md:text-[4rem] text-5xl text-[#ffe718] flex md:gap-3 items-center '>
                        <img src={logo} className='md:w-32 md:h-32 h-28 w-28 md:block hidden' alt="" />Features </h1>
                    <h1 className='text-white text-lg text-center font-chakra   md:w-[70%]'>A gaming ecosystem with deflationary mechanism that integrates
                        staking with gameplay, allowing stakers to earn better rewards
                        through increasing their in game skill and experience levels.</h1>
                </div>

            </div>
            <div className="flex w-full py-10 justify-center items-start flex-wrap bg-[#FFB7B7] px-5 ">

                <div className='flex items-center justify-center md:mt-10 flex-wrap md:gap-0 gap-4'>
                    <div className='mt-12 flex flex-col md:w-[50%] md:gap-8 gap-4'>
                        <h1 className='uppercase font-Pixelify font-bold  text-center leading-[50px] md:text-[4rem] text-5xl text-[#ffe718] flex md:gap-3 items-center '>
                            <img src={log2} className='w-32 h-32 md:block hidden' alt="" />BCDG  BRC20 Token</h1>
                        <h1 className='text-white md:text-lg text-md text-center font-chakra w-[100%]'>$BCDG is a native token of bitcoin dog ecosystem that can be used as a payment to purchase items inside our nft marketplace ,for in-game purchases and power-ups and join game tournament organised by bitcoin dog team. $BCDG is originally inscribed on bitcoin and part of its bridged / wrapped to Binance smart chain network.</h1>
                    </div>



                </div>
                {/* <div className='flex flex-col justify-start gap-6 items-start w-full'>
                    <h1 className='uppercase  mx-auto font-Pixelify font-bold   leading-[50px] md:text-[4rem] text-3xl text-[#ffe718] flex md:gap-3 items-center '>
                        <img src={log1} className='w-32 h-32' alt="" />Features BITCOIN DOG</h1>
                </div> */}
                <div className="w-full flex  items-center flex-wrap justify-center border-4 md:border-0  border-white rounded-3xl">
                    <div className="md:w-[25%] ">
                        <img src={coin} alt='' className='md:w-2/5 w-1/4 mx-auto' />
                    </div>
                    <div className='md:w-[60%] flex  flex-wrap gap-5'>

                        <div className='md:w-2/5 w-[47%]  flex flex-col justify-start items-center gap-4 '>
                            <h1 className='text-white text-lg  font-chakra font-bold'>COIN : </h1>
                            <h1 className=' text-2xl font-bold text-[#ffe718]'> $BCDG</h1>
                        </div>
                        <div className='md:w-2/5 w-[47%] flex flex-col justify-start items-center gap-4 '>
                            <h1 className='text-white text-lg  font-chakra font-bold'>Total Supply : </h1>
                            <h1 className=' text-2xl font-bold text-[#ffe718]'> 1 billion</h1>
                        </div>
                        <div className='md:w-2/5 w-[47%] flex flex-col justify-start items-center gap-4 '>
                            <h1 className='text-white text-lg  font-chakra font-bold'>Ordinals BRC  : </h1>
                            <h1 className=' text-2xl font-bold text-[#ffe718]'> TBA</h1>
                        </div>
                        <div className='md:w-2/5 w-[47%] flex flex-col justify-start items-center gap-4'>
                            <h1 className='text-white text-lg  font-chakra font-bold'>CA (BSC)  : </h1>
                            <h1 className=' text-2xl font-bold text-[#ffe718]'> TBA</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex w-full pb-5  justify-center items-center flex-wrap bg-[#87C4FF]" id='game'>
                <div className="z-20 relative bottom-0 left-0 w-full h-4 transform md:rotate-[0deg] rotate-[0deg] origin-bottom-left">
                    <InfiniteSlider text='PLAY , CONNECT & CREATE ' />
                </div>
                <div className='flex flex-col justify-start gap-6 items-center w-full pt-16 pb-3 px-5'>

                    <h1 className='uppercase  mx-auto font-Pixelify  font-bold  text-center leading-[50px] md:text-[4rem] text-5xl text-pink-500 flex md:gap-3 items-center '>
                        <img src={logo} className='w-32 h-32 md:block hidden' alt="" /><div className="">
                            <span className="text-white">PLAY, CONNECT ,</span>  CREATE </div></h1>

                </div>
                <div className=" w-full relative">
                    <img src={game} alt='' className='md:w-2/5 w-4/5 mx-auto rounded-xl' />
                </div>
                <div className='md:w-3/5 flex flex-col md:items-center md:gap-8 gap-2 py-4'>
                    <p className="text-white text-lg  text-center">Bitcoin dog has first play to earn game on bitcoin
                        ecosystem built by a team of over 25 professionals including skilled
                        artists, experienced game designers, and developers. Our game buit
                        on two version Single-player and Multiplayer Version.</p>
                </div>
            </div>
        </div>
    )
}

export default Feautres
