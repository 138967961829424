import React from 'react'
import logo from '../assets/Dog Transparent 1.png'
import n1 from '../assets/Dog Base 1.jpg'
import n2 from '../assets/Dog Base 2.jpg'
import n3 from '../assets/Dog Base 3.jpg'
import n4 from '../assets/Dog Base 4.jpg'
import n5 from '../assets/Dog Base 5.jpg'
import n6 from '../assets/Dog Base 6.jpg'
import n7 from '../assets/Dog Base 7.jpg'
import n8 from '../assets/Dog Base 8.jpg'
// import n9 from '../assets/Dog Base 9.jpg'
// import n10 from '../assets/Dog Base 10.jpg'
// import n12 from '../assets/Dog Base 11.jpg'
// import n15 from '../assets/Dog Base 14.jpg'
// import n16 from '../assets/Dog Base 15.jpg'
import InfiniteSlider from './InfiniteSlider'
import { IoIosArrowForward } from "react-icons/io";


const imgs = [
    { img: n1 },
    { img: n2 },
    { img: n3 },
    { img: n4 },
    { img: n5 },
    { img: n6 },
    { img: n7 },
    { img: n8 },
    // {img: n9},
    // {img: n10},
    // {img: n12},
    // {img: n15},
    // {img: n16},
]

const NftCollection = () => {
    return (
        <div className='bg-[#5ED86E]'>
            <div className="z-20 relative bottom-0 left-0 w-full h-4 transform md:rotate-[0deg] rotate-[0deg] origin-bottom-left">
                <InfiniteSlider text='BITCOIN DOG NFTS' />
            </div>
            <div className='w-full   py-20 md:px-24 px-5 ' >
                <div className='flex flex-col justify-start gap-6 items-start w-full'>
                    <h1 className='uppercase  mx-auto font-Pixelify font-bold  text-center md:leading-[50px] md:text-[4rem] text-5xl text-pink-500 flex md:gap-3 items-center '>
                        <img src={logo} className='w-32 h-32 md:block hidden' alt="" /><div className="">
                            BITCOIN DOG NFTS </div></h1>


                    <div className='flex flex-col w-full md:gap-8 gap-4'>
                        <h1 className='uppercase font-chakra font-bold md:text-2xl md:w-3/4 mx-auto text-center text-xl text-white'>
                            Bitcoin dog NFT is the collection of 1111 heroes inscribed on BRC20 Network & With the use of Our Heroes NFT player can participate in Tournament.

                            <div className="flex gap-2 items-center">
                                <IoIosArrowForward /> Staking Rewards & Airdrops
                            </div>                          
                              <div className="flex gap-2 items-center">
                                <IoIosArrowForward /> POPULATE YOUR LAND WITH GAME MODES
                              </div>
                            <div className="flex gap-2 items-center">
                                <IoIosArrowForward /> PLAYERS CAN BUY LAND
                                ASSETS TO CREATE IN-GAME EXPERIENCES
                            </div>
</h1>
                        {/* <h1 className='uppercase font-chakra font-bold md:text-2xl text-xl text-[#ffe718]'>POPULATE YOUR LAND WITH GAME MODES</h1>
                        <h1 className='uppercase font-chakra font-bold md:text-2xl text-xl text-[#ffe718]'>PLAYERS CAN BUY LAND ASSETS TO CREATE IN-GAME EXPERIENCES </h1>
                       
{/*  */}
                    </div>
                </div>
                <div className="flex justify-center items-center md:gap-5 gap-2 flex-wrap py-10">
                    {imgs.map((img, i) => (
                        <div key={i} className="md:w-[23%] w-[47%] relative md:py-6">
                            <img src={img.img} className='md:p-4 md:w-3/4 w-full relative border-double border-4  border-white z-10' alt="" />
                            {/* <img src={picFrame} className='top-0 left-0 md:w-3/4 w-full h-4/5 absolute ' alt="" /> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default NftCollection