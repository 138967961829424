import React from 'react'
import InfiniteSlider from '../InfiniteSlider'
import CustomFaq from './customFaq'

const faqs = [
    { question: "WHAT IS BITCOIN DOG ?", answer: "Bitcoin dog is a free to play and play to earn web3 game buit on bitcoin ecosystem where you can make friends and discover and level up. Bitcoin doge is real – time single player and multiplayer game developed in unreal engine and on the BRC20 network." },
    { question: "How It’s Different From Other ?", answer: "Firstly, Bitcoin dog is comminited to brc community for creating impactful for BTC ECOSYSTEM, We Are highly granting on our platform & Game to increase your earning in BRC ECOSYSTEM. Our Game & Platform is the main role for improving the users experience & Metaverse Experience." },
    { question: "How We Can Get Early Granted Access On $BCDG ?", answer: "You can stay tuned on our social media platform to know all the information & waiting for Upcomming announcements, events & Future Plans. We happily to share all the information to our $BCDG Community Members." },
]
const FAQS = () => {
    return (
        <div>
            <div className='bg-[#5ED86E]'>
                <div className="z-20 relative bottom-0 left-0 w-full h-4 transform md:rotate-[0deg] rotate-[0deg] origin-bottom-left">

                    <InfiniteSlider text='FREQUENTLY ASKED QUESTIONS' />
                </div>
                <div className='w-full h-full  py-20 md:px-24 px-5  relative' >
                    <h1 className='uppercase font-Pixelify font-bold    text-center leading-[50px] md:text-[4rem] text-5xl text-[#ffe718] flex gap-3 items-center flex-wrap'>
                        FREQUENTLY ASKED QUESTIONS
                    </h1>
                    <div className="py-6">
                        <CustomFaq faqs={faqs} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQS