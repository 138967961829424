import React from 'react';
import dog from '../assets/dog.jpg'
import { RxCross2 } from "react-icons/rx";


const Popup = ({ isOpen, onClose }) => {
    return (
        <div className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">

                    <div className="bg-fixed rounded-xl h-[20rem] w-full" style={{ background: `linear-gradient(to right, rgba(0, 0, 0, .50), rgba(0, 0, 0, .50)), url(${dog})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                        <div className='flex justify-end px-2' >
                            <RxCross2 onClick={onClose} className='text-white text-3xl' />
                        </div>
                        <div className='h-full w-full flex items-center justify-center'>
                            <h1 className='text-3xl font-bold font-Pixelify  text-yellow-500'>COMING SOON</h1>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;
