import React from 'react'
import dog from '../assets/dog.jpg'


const GamePlay = () => {
    return (
        <>
            <div className="bg-fixed h-screen" style={{ background: `linear-gradient(to right, rgba(0, 0, 0, .50), rgba(0, 0, 0, .50)), url(${dog})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                <div className='h-full w-full flex items-center justify-center'>
                    <h1 className='text-5xl font-bold font-Pixelify  text-yellow-500'>COMING SOON</h1>
                </div>
            </div>
        </>
    )
}

export default GamePlay
