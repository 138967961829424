import React from 'react';
import Marquee from "react-fast-marquee";

const SliderItem = ({ text }) => (
  <h1 className='md:mx-10 mx-8 font-bold text-xl md:text-3xl font-Pixelify'>{text}</h1>
);

const InfiniteSlider = ({ text }) => {  // <-- Add curly braces to destructure props
  const items = Array.from({ length: 10 }, (_, index) => index + 1);

  return (
    <Marquee className='bg-[#e3ff38] py-2 md:py-3'>
      {items.map(item => (
        <SliderItem key={item} text={text} />
      ))}
    </Marquee>
  );
};

export default InfiniteSlider;
