import React from 'react'

const QesContent = (props) => {
    return (
        <>
            <div className='flex gap-2 md:p-12 p-4'>
                <div className='flex flex-col gap-2'>
                    <h1 className='text-3xl font-chakra font-bold text-white' >
                        {props.year}
                        {/* 2024 */}
                        </h1>
                    <h1 className='text-5xl font-semibold text-white transform scale-y-150'>
                        {props.Qes}
                        {/* Q3 */}
                    </h1>
                </div>
                <div className='border-l-4 h-36 border-white ml-2'></div>
                <div className='flex flex-col gap-2'>
                    <div>
                        <img src="" alt="" />
                        <h1 className='text-xl text-white italic font-bold font-chakra flex gap-2 items-center'>
                            {/* <MdDoubleArrow /> */}
                            {props.des1}
                            {/* Major concept created */}
                        </h1>
                    </div>
                    <div>
                        <img src="" alt="" /> 
                        <h1 className='text-xl text-white italic font-bold font-chakra flex gap-2 items-center'>
                            {/* <MdDoubleArrow /> */}
                            {props.des2}
                            {/* Game devlopmemt */}
                        </h1>
                    </div>
                    <div>
                        <img src="" alt="" />
                        <h1 className='text-xl text-white italic font-bold font-chakra flex gap-2 items-center'>
                            {/* <MdDoubleArrow /> */}
                            {props.des3}
                            {/* Open beta */}
                        </h1>
                    </div>
                    <div>
                        <img src="" alt="" />
                         <h1 className='text-xl text-white italic font-bold font-chakra flex gap-2 items-center'>
                            {/* <MdDoubleArrow /> */}
                            {props.des4}
                            {/* website development */}
                        </h1>
                    </div>
                    <div>
                        <img src="" alt="" />
                        <h1 className='text-xl text-white italic font-bold font-chakra flex gap-2 items-center'>
                          
                            {props.des5}
                            {/* Major concept created */}
                        </h1>
                    </div>
                    <div>
                        <img src="" alt="" />
                        <h1 className='text-xl text-white italic font-bold font-chakra'>
                            {props.des6}
                            {/* Major concept created */}
                        </h1>
                    </div>
                    <div>
                        <img src="" alt="" />
                        <h1 className='text-xl text-white italic font-bold font-chakra'>
                            {props.des7}
                            {/* Major concept created */}
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QesContent
