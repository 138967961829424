import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Container, Button } from '@mui/material';
import QesContent from './QesContent';
import { MdArrowForwardIos } from "react-icons/md";
import InfiniteSlider from './InfiniteSlider'




const steps = ['Q3 2023', 'Q4 2023', 'Q1 2024', 'Q2 2024'];

const RoadMap = () => {
    const [activeStep, setActiveStep] = useState(0);

    const handleStepClick = (index) => () => {
        setActiveStep(index);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    return (
        <div className="bg-[#267931]" id='roadmap'>
            <div className="z-20 relative bottom-0 left-0 w-full h-4 transform md:rotate-[0deg] rotate-[0deg] origin-bottom-left">

                <InfiniteSlider text='ROADMAP BITCOIN DOG' />
            </div>
            <div className='md:px-24 px-5 py-10 ' style={{ display: 'flex', flexDirection: 'column', minHeight: '60vh' }}  >

                <div style={{ flex: 1 }}>
                    <div style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                        <QesContent
                            year="2023"
                            Qes="Q3"
                            des1="> Major concept created"
                            des2="> Game devlopmemt"
                            des3="> Open Beta"
                            des4="> Website development"
                        />
                    </div>
                    <div style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                        <QesContent
                            year="2023"
                            Qes="Q4"
                            des1="> Closed Beta "
                            des2="> Private sale"
                            des3="> Marketing campian"
                            des4="> Release nft collection"
                            des5="> Airdrop campian"
                        />
                    </div>
                    <div style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                        <QesContent
                            year="2024"
                            Qes="Q1"
                            des1="> Public sale phase start"
                            des2="> BCDG listing"
                            des3="> BCDG Listing on Big CEX"
                            des4="> Airdrop distribution"
                            des5="> Mobile version launch"
                            des6="> Staking & farming"
                            des7="> Game upgradation"
                        />
                    </div>
                    <div style={{ display: activeStep === 3 ? 'block' : 'none' }}>
                        <QesContent
                            year="2024"
                            Qes="Q2"
                            des1="> New map launch"
                            des2="> New characters"
                            des3="> Tournament"
                            des4="> Game Developement"
                            des6="> NFT Integration"
                        />
                    </div>

                    <div className='flex gap-3'>
                        <Button sx={{ bgcolor: '#374253', ml: 6 }} variant="contained" onClick={handleNext} disabled={activeStep === steps.length - 1}>
                            <Typography className='flex  items-center gap-2'>NEXT <MdArrowForwardIos /></Typography>
                        </Button>
                        <div className='w-fit bg-white px-2 p-2 rounded-md'>
                            <h1 className='uppercase font-chakra font-bold'>Full RoadMap</h1>
                        </div>
                    </div>

                </div>
                <div style={{ padding: '', borderTop: '1px solid #ccc' }}>
                    <Container>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={label} onClick={handleStepClick(index)}>
                                    <StepLabel sx={{
                                        '& .MuiStepLabel-label': {
                                            color: 'white',
                                            fontWeight: 'bold' // Change to your desired color for label text
                                        },
                                        '& .MuiStepIcon-root': {
                                            color: index === activeStep ? '#22b968' : 'inherit', // Change to your desired color for the active step icon
                                        },
                                    }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default RoadMap;
