import React from 'react'
import Header from '../components/Header'
import About from '../components/About'
import Feautres from '../components/Feautres'
import RoadMap from '../components/RoadMap'
import Team from '../components/team'
import NftCollection from '../components/nftCollection'
import FAQS from '../components/faq'
import { Link } from 'react-router-dom'
import { BsMedium } from "react-icons/bs";
import telegrm from '../assets/telegram.png'
import twiter from '../assets/twiter.png'
import { MdOutlineMail } from 'react-icons/md'

const Home = () => {
  return (
    <>
      <Header/>
      <About/> 
      <Feautres/>
      <NftCollection/>
      <RoadMap/>
      <Team/>
      <FAQS/>
      <div className=" bg-[#5ED86E]">
      <div className="md:w-1/2 w-full px-5 py-5 mx-auto bg-[#5ED86E]">
        <div className='flex  items-center justify-center gap-2 md:gap-4'>
          <Link to='https://t.me/BitcoinDogOfficial' >
            <img src={telegrm} className='h-12 md:h-14 w-12 md:w-14' alt="" />
          </Link>
          <Link to='https://x.com/bitcoindogworld' >
            <img src={twiter} className='h-12 md:h-14 w-12 md:w-14' alt="" />
          </Link>
            <Link to='https://medium.com/@bitcoindogofficial' >
              <div className='h-12 md:h-14 border-2 border-black w-12 md:w-14 bg-gray-200 rounded-full flex justify-center items-center'><BsMedium className='text-4xl'/></div>
            </Link>
            <Link to='Bitcoindogofficial@gmail.com' >
              <div className='h-12 md:h-14 border-2 border-black w-12 md:w-14 bg-gray-200 rounded-full flex justify-center items-center'><MdOutlineMail className='text-4xl' /></div>
            </Link>
        </div>
      </div>
      </div>
    </>
  )
}

export default Home
