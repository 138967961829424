import React from 'react'
import team1 from '../assets/1.JPG'
import team2 from '../assets/2.JPG'
import team3 from '../assets/3.JPG'
import team4 from '../assets/4.JPG'
import team5 from '../assets/5.JPG'
import picFrame from '../assets/photo-frame.png'


import Slider from "react-slick";
import { IoIosArrowBack } from 'react-icons/io';
import InfiniteSlider from './InfiniteSlider'


function SampleNextArrow(props) {
    const { onClick, currentSlide, slideCount } = props;
    const isLastSlide = currentSlide === slideCount - 1;

    return (
        <div
            onClick={onClick}
            className={`md:flex hidden absolute text-lg text-black top-[30%] right-[-1.5rem]  rounded-full bg-white z-10 cursor-pointer ${isLastSlide ? 'md:hidden' : 'md:block'
                }`}
        >
            <div className="bg-white p-2 rounded-xl shadow-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M8.44116 19.9412L16.4412 12.9412L8.44116 5.94116" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg></div>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick, currentSlide } = props;
    const isFirstSlide = currentSlide === 0;

    return (
        <div
            onClick={onClick}
            className={`md:flex hidden absolute text-lg text-black top-[30%] left-[-1.5rem]  shadow-md rounded-full bg-white z-10  ${isFirstSlide ? 'md:hidden' : 'md:block'
                }`}
        >
            <div className="bg-white p-2  rounded-xl shadow-md text-2xl font-semibold">
                <IoIosArrowBack />
            </div>
        </div>
    );
}

   const brands =[
       { img: team1, name: "JAM BITCOIN", position:'CEO & CO-FOUNDER'},
       { img: team2, name: "DANIEL KNIGHT", position:'CTO'},
       { img: team3, name: "LISA LAMEN", position:'CMO'},
       { img: team4, name: "HEAROAD MELLOW", position:'MARKETING MANAGER'},
       { img: team5, name: "GEORGE", position:'LEAD GAME ARTIST'},
   ]
const Team = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 1924,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='bg-[#bb7752] ' id='team'>
            <div className="z-20 relative bottom-0 left-0 w-full h-4 transform md:rotate-[0deg] rotate-[0deg] origin-bottom-left">

                <InfiniteSlider text='TEAM MEMBER OF BITCOIN DOG' />
            </div>
            <div className="md:py-20 py-10 md:px-20 px-5  ">
                
                <h1 className='uppercase font-Pixelify font-bold  md:text-6xl text-xl text-[#ffe718] py-5'>
                    Team Member
                </h1>

                <Slider {...settings}>
                    {brands.map((brand ) => (
                        <div data-aos="fade-up" key={brand.id} className="flex flex-col justify-center items-center">
                            <div className="relative md:py-5 py-6 ">
                                <img src={brand.img} alt='' className='relative top-2 md:left-6 left-7 z-10  md:w-3/5 w-4/5  hover:scale-105 duration-300 transition-all' />
                                <img src={picFrame} alt='' className='absolute top-0 left-0  md:w-3/4   hover:scale-105 duration-300 transition-all' />
                            </div>
                            <h1 className="md:text-xl text-xl font-semibold text-white  text-center  py-2">{brand.name}</h1>
                            <p className="md:text-md text-md font-normal  text-white text-center py-1">{brand.position} </p>
                        </div>
                    ))}
                </Slider>
                {/* <div className="py-5 flex justify-center items-center flex-wrap gap-3">
                    <div className="lg:w-[23%] md:w-[47%] w-full space-y-3">
                        <img src={ team1} alt='' className='w-3/4 h-1/4 mx-auto bg-blue-500 transform -skew-x-12'/>
                        <h1 className='uppercase font-Pixelify font-medium  md:text-xl text-md text-[#ffe718] '>CEO ( CO-FOUNDER )</h1>
                    </div>
                    <div className="lg:w-[23%] md:w-[47%] w-full space-y-3">
                        <img src={team2} alt='' className='w-3/4 h-1/4 mx-auto bg-blue-500 transform -skew-x-12' />
                        <h1 className='uppercase font-Pixelify font-medium  md:text-xl text-md text-[#ffe718] '>CEO ( CO-FOUNDER )</h1>
                    </div><div className="lg:w-[23%] md:w-[47%] w-full space-y-3">
                        <img src={team3} alt='' className='w-3/4 h-1/4 mx-auto bg-blue-500 transform -skew-x-12' />
                        <h1 className='uppercase font-Pixelify font-medium  md:text-xl text-md text-[#ffe718] '>CEO ( CO-FOUNDER )</h1>
                    </div><div className="lg:w-[23%] md:w-[47%] w-full space-y-3">
                        <img src={team4} alt='' className='w-3/4 h-1/4 mx-auto bg-blue-500 transform -skew-x-12' />
                        <h1 className='uppercase font-Pixelify font-medium  md:text-xl text-md text-[#ffe718] '>CEO ( CO-FOUNDER )</h1>
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default Team