import React, { useState } from 'react'
import logo from '../assets/Dog Transparent 1.png'
import logo2 from '../assets/logoo.png'
import telegrm from '../assets/telegram.png'
import twiter from '../assets/twiter.png'
import walletBg from '../assets/wallet-bg.png'
import wallet from '../assets/wallet.png'
import { Link } from 'react-router-dom'
import Popup from './PopUp'


const menuItems = [
    { label: "Home", url: "/" },
    { label: "ABOUT", url: "#ABOUT" },
    { label: "ROADMAP ", url: "#roadmap " },
    { label: "TEAM ", url: "#team" },
    { label: "GAMEPLAY", url: "#game" },
    // { label: "MARKETPLACE ", url: "/marketPlace" },
    // { label: "LAUNCHPAD ", url: "/launchPad" },
];


const Navabar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    // const [selectedItem, setSelectedItem] = useState('');

    const openModal = (item) => {
        setIsOpen(true);
        // setSelectedItem(item);
    };

    const closeModal = () => {
        setIsOpen(false);
        // setSelectedItem('');
    };

    return (
        <>
            <div className='flex justify-between items-center px-2 pr-7 md:px-24 py-4 w-full'>
                <div className='flex items-center gap-2'>
                    <img src={logo} className='h-12 md:h-14 w-12 md:w-14 rounded-full' alt="" />
                    <img src={logo2} className='md:w-2/5 w-2/5' alt="" />
                </div>
                <div className='flex gap-4 items-center md:gap-8 w-full justify-end '>
                    <div className='md:flex hidden items-center gap-2 md:gap-4'>
                        <Link to='t.me/BitcoinDogOfficial' >
                            <img src={telegrm} className='h-12 md:h-14 w-12 md:w-14' alt="" />
                        </Link>
                        <Link to='https://x.com/bitcoindogworld' >
                            <img src={twiter} className='h-12 md:h-14 w-12 md:w-14' alt="" />
                        </Link>
                    </div>
                    <img src={wallet} className='block md:hidden w-12 h-12' alt="" />
                    <div className='relative hidden md:block '>
                        <img src={walletBg} className='md:w-auto w-32' alt="" />
                        <h1 className='font-normal absolute top-1/2 left-[56%] w-full transform -translate-x-1/2 -translate-y-1/2 font-Pixelify text-xl md:text-3xl text-[#ce583b]'>CONNECT WALLET</h1>
                    </div>

                    <button onClick={() => setIsModalOpen(!isModalOpen)} className="lg:hidden block ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                        >
                            <path
                                d="M3.5 15H21.5M3.5 9H21.5"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            {isModalOpen && <div className="bg-black absolute md:top-28 top-22 left-0 w-full px-10 z-20 py-10 transition-all duration-300">
                <div className="flex flex-col justify-center gap-2 ">
                    {menuItems.map((item) => (
                        <a
                            key={item.label}
                            href={item.url}
                            className="text-white font-medium text-lg hover:scale-105 hover:text-yellow-300 duration-300 transition-all"
                        >
                            {item.label}
                        </a>
                    ))}
                    <div>
                        <span onClick={() => openModal()} className="text-white font-medium text-lg hover:scale-105 hover:text-yellow-300 duration-300 transition-all" >MARKETPLACE</span>
                    </div>
                    <div>
                        <span onClick={() => openModal()} className="text-white font-medium text-lg hover:scale-105 hover:text-yellow-300 duration-300 transition-all" >LAUNCHPAD</span>
                    </div>
                    <Popup isOpen={isOpen} onClose={closeModal} />
                </div>
            </div>}
        </>
    )
}

export default Navabar
