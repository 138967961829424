import React from 'react'
// import pic1 from '../assets/Dog Base 1.jpg'
// import pic2 from '../assets/Dog Base 2.jpg'
// import pic3 from '../assets/Dog Base 4.jpg'
// import pic4 from '../assets/Dog Base 3.jpg'
import logo from '../assets/Dog Transparent 1.png'
// import picFrame from '../assets/photo-frame.png'
import InfiniteSlider from './InfiniteSlider'

const About = () => {
    return (
        <div className='bg-[#5ED86E]' id='ABOUT'>
            <div className="z-20 relative bottom-0 left-0 w-full h-4 transform md:rotate-[0deg] rotate-[0deg] origin-bottom-left">
                <InfiniteSlider text='ABOUT BITCOIN DOG' />
            </div>
            <div className='w-full h-full  py-20 md:px-24 px-5  relative' >
              
                <div className='flex flex-col justify-center gap-6 items-center w-full'>
                    <h1 className='uppercase font-Pixelify font-bold  text-center md:leading-[70px] md:text-[4rem] text-5xl text-[#ffe718] flex md:gap-3 items-center justify-start '>
                        <img src={logo} className='md:w-32 h-32 w-24' alt="" />
                        About <br />
                        BITCOIN DOG
                        </h1>
                    <h1 className='text-white text-lg font-chakra text-center  md:w-[70%]'>Bitcoin dog is a free to play and play to earn web3 game
                        buit on bitcoin ecosystem where you can make friends and
                        discover and level up. Bitcoin doge is real – time single player and
                        multiplayer game developed in unreal engine and on the BRC20
                        network.</h1>
                </div>
                {/* <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mt-10'>
                    <div className='relative flex justify-center items-center' >
                        <img src={picFrame} className='w-[18rem]' alt="" />
                        <div className='absolute top-[50%] w-[75%] left-1/2 md:left-[50%]  transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-12'>
                            <img src={pic1} className='w-full' alt="" />
                        </div>
                    </div>
                    <div className='relative flex justify-center items-center' >
                        <img src={picFrame} className='w-[18rem]' alt="" />
                        <div className='absolute top-[50%] w-[75%] left-1/2 md:left-[50%]  transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-12'>
                            <img src={pic2} className='w-full' alt="" />
                        </div>
                    </div>
                    <div className='relative flex justify-center items-center' >
                        <img src={picFrame} className='w-[18rem]' alt="" />
                        <div className='absolute top-[50%] w-[75%] left-1/2 md:left-[50%]  transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-12'>
                            <img src={pic3} className='bg-black w-full' alt="" />
                        </div>
                    </div>
                    <div className='relative flex justify-center items-center' >
                        <img src={picFrame} className='w-[18rem]' alt="" />
                        <div className='absolute top-[50%] w-[75%] left-1/2 md:left-[50%]  transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-12'>
                            <img src={pic4} className='w-full' alt="" />
                        </div>
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default About
